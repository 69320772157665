// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import("@rails/activestorage")
import("local-time")

//= require jquery
//= require jquery.turbolinks
//= require jquery.autosize

import "trix"
import "@rails/actiontext"

import * as bootstrap from "bootstrap"

import jquery, {ready} from "jquery";
window.jQuery = jquery;
window.$ = jquery;

import("./vendor/best_in_place")
import("./vendor/best_in_place.jquery-ui")
import("./packs/rails_admin")

import "chartkick/chart.js"

document.addEventListener("DOMContentLoaded", function() {
    /* Activating Best In Place */
    jQuery(".best_in_place").best_in_place();
});

document.addEventListener("turbo:load", () => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })

    // $('[data-toggle="tooltip"]').tooltip()
    // $('[data-toggle="popover"]').popover()
    jQuery(".best_in_place").best_in_place();
})